<script setup>
    import { ref, computed, onMounted, onBeforeUnmount } from "vue";
    import { mobileUtils } from "o365-mobile";
    import ContextSelector from 'o365.pwa.vue.components.ContextSelector.vue';
    
    const { isMobile } = mobileUtils;

    const props = defineProps({
        src: {
            type: String,
            required: true
        },
        appState: {
            type: Object,
            required: true
        },
        startSync: {
            type: Function,
            required: true
        },
        getCheckedOutRecordCount: {
            type: Function,
            required: true
        }
    });

    const iframe = ref(null);
    const iframeWindow = ref(null);
    const navigationHistory = ref([]);
    const isIframeLoading = ref(true);

    const disableDownload = computed(() => {
        return props.appState.loadingCheckedOutRows || props.appState.checkedOutRecordCount === 0;
    });

    const goBackIframe = () => {
        if (navigationHistory.value.length > 1) {
            navigationHistory.value.pop();
            const navigateToUrl = navigationHistory.value[navigationHistory.value.length - 1];
            iframe.value.src = navigateToUrl.toString();
        }
    }

    const reloadIframe = () => {
        iframeWindow.value.location.reload();
    }

    const goToRegisterIframe = () => {
        if (navigationHistory.value.length > 1) {
            const navigateToUrl = navigationHistory.value[0];
            navigationHistory.value = [];
            iframe.value.src = navigateToUrl.toString();
        }
    }

    const onIframeAfterNavigate = () => {
        isIframeLoading.value = true;

        const iframeUrl = new URL(iframeWindow.value.location.href);
        const iframeParams = new URLSearchParams(iframeUrl.search);

        // Make sure the iframe has hidenav
        if (!iframeParams.has('HideNav')) {
            iframeParams.set('HideNav', 'true');
            iframeUrl.search = iframeParams.toString();

            iframe.value.src = iframeUrl.toString();
            return;
        }

        isIframeLoading.value = false;

        const urlExistsInHistory = navigationHistory.value.some(historyUrl => {
            return `${iframeUrl.hostname}${iframeUrl.pathname}` === `${historyUrl.hostname}${historyUrl.pathname}`;
        });

        if (urlExistsInHistory) {
            return;
        }

        navigationHistory.value.push(iframeUrl);
    }

    const syncCheckedOutItems = () => {
        if (disableDownload.value) {
            return;
        }

        props.startSync('OFFLINE-SYNC');
    }

    const onContextChanged = (contextID) => {
        iframeWindow.value?.postMessage(`{"type": "contextUpdate", "id": "${contextID}"}`);
    }

    const onMessage = (event) => {
        try {
            const messageObject = JSON.parse(event.data);

            switch (messageObject.type) {
                case 'checkOutUpdate':
                    props.getCheckedOutRecordCount();
                    break;
            }
        } catch (_reason) {}
    }

    onMounted(() => {
        props.getCheckedOutRecordCount();

        iframeWindow.value = iframe.value.contentWindow ?? null;
        iframe.value.addEventListener('load', onIframeAfterNavigate, false);
        
        window.addEventListener('message', onMessage, false);
    });

    onBeforeUnmount(() => {
        window.removeEventListener('message', onMessage, false);
        iframe.value.removeEventListener('load', onIframeAfterNavigate, false);
    });
</script>

<template>
    <ORowContainer class="gap-1 bg-white">
        <div v-if="isIframeLoading" class="overlay">
            <div class="overlay__inner">
                <div class="overlay__content"><span class="spinner"></span></div>
            </div>
        </div>

        <div class="d-flex align-items-center gap-3 py-2" :class="{ 'justify-content-around border-bottom': isMobile, 'justify-content-between mx-3': !isMobile }">
            <div class="o365-checkout-download d-flex align-items-center px-2 py-1 bg-light rounded shadow-sm" :class="{ 'cursor-pointer': !disableDownload }" style="white-space:nowrap;" @click="syncCheckedOutItems">
                <span class="badge text-bg-primary me-2">{{ appState.loadingCheckedOutRows ? '0' : appState.checkedOutRecordCount }}</span>
                
                <span>{{ $t('Items checked out') }}</span>
                
                <i class="bi bi-download text-primary ms-2"></i>
            </div>

            <template v-if="!isMobile">
                <div class="d-flex justify-content-around gap-2">
                    <button class="btn btn-link btn-sm" :disabled="navigationHistory.length > 1 ? null : true" @click="goBackIframe">
                        <i class="bi bi-chevron-left"></i>
                        {{$t('Back')}}
                    </button>

                    <button class="btn btn-link btn-sm" @click="reloadIframe">
                        <i class="bi bi-arrow-clockwise me-1"></i>
                        {{$t('Reload')}}
                    </button>

                    <button class="btn btn-link btn-sm" :disabled="navigationHistory.length > 1 ? null : true" @click="goToRegisterIframe">
                        <i class="bi bi-layout-text-window me-1"></i>
                        {{$t('Go to register')}}
                    </button>
                </div>
            </template>
            
            <div :class="{ 'me-2': isMobile }" :style="isMobile ? 'max-width:40vw;font-size:90%;' : null">
                <ContextSelector @contextChanged="onContextChanged"></ContextSelector>
            </div>
        </div>

        <iframe ref="iframe" :src="src" class="h-100 w-100" style="margin-top:-4px!important;margin-bottom:-5px!important;" frameborder="0"></iframe>

        <template v-if="isMobile">
            <div class="d-flex justify-content-around border-top gap-2 py-2">
                <button class="btn btn-link btn-sm" :disabled="navigationHistory.length > 1 ? null : true" @click="goBackIframe">
                    <i class="bi bi-chevron-left"></i>
                    {{$t('Back')}}
                </button>

                <button class="btn btn-link btn-sm" @click="reloadIframe">
                    <i class="bi bi-arrow-clockwise me-1"></i>
                    {{$t('Reload')}}
                </button>

                <button class="btn btn-link btn-sm" :disabled="navigationHistory.length > 1 ? null : true" @click="goToRegisterIframe">
                    <i class="bi bi-layout-text-window me-1"></i>
                    {{$t('Go to register')}}
                </button>
            </div>
        </template>
    </ORowContainer>
</template>

<style scoped>
    .o365-checkout-download[disabled] {
        opacity: 0.7;
        pointer-events: none;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .btn-link {
        text-decoration: none;
    }
</style>